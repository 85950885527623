import React, { useState, useRef, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import weightarrow from '../assets/weight-arrow.png';
import frontimg from '../assets/front-girl 1-01.png';
import backimg from '../assets/back-girl 4-01.png';
import sideimg from '../assets/side girl 3-01.png';
import stomachimg from '../assets/stomach girl.png';
import thighsimg from '../assets/girl leg-01.png';
import { Margin } from '@mui/icons-material';
import finger from '../assets/finger-img.png';

const Form = ({ label, name, value, onChange, onSliderChange, options, helperText }) => {
    const [selectedOption, setSelectedOption] = useState(value || '');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [details, setDetails] = useState('');
    const [frontImage, setFrontImage] = useState(null);
    const [backImage, setBackImage] = useState(null);
    const [sideImage, setSideImage] = useState(null);
    const [stomachImage, setStomachImage] = useState(null);
    const [thighsImage, setThighsImage] = useState(null);

    const frontFileInputRef = useRef(null);
    const backFileInputRef = useRef(null);
    const sideFileInputRef = useRef(null);
    const stomachFileInputRef = useRef(null);
    const thighsFileInputRef = useRef(null);


    useEffect(() => {
        // This will update the selected option if the value prop changes
        setSelectedOption(value);
    }, [value]);

    const handleSelectChange = (optionValue) => {
        setSelectedOption(optionValue);
        setIsDropdownOpen(false);
        onChange({ target: { name, value: optionValue } });
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const handleDetailsChange = (e) => {
        const { value } = e.target;
        setDetails(value);
        onChange({ target: { name: `${name}Details`, value } });
    };

    const handleFileChange = (e, setImage, targetName) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
                onChange({ target: { name: targetName, value: reader.result } });
            };
            reader.readAsDataURL(file);
        }
    };
    const getPlaceholder = () => {
        switch (name) {
            case "name":
                return "Max Muster";
            case "dateOfBirth":
                return "Select your date of birth";
            case "Email":
                return "Name@beispiel.com";
            case "bodyhight":
                return "170";
            case "currentbody":
                return "70";
            case "myperseverance":
                return "5";
            case "dietwithtimestamp":
                return "Tag 1 (Wochentag) \n\
08:00 Uhr: Joghurt mit Beeren \n\
12:30 Uhr: Salat mit Hähnchen \n\
15:00 Uhr: Apfel \n\
19:00 Uhr: Reis mit Gemüse\n\
Tag 2 (Wochentag)\n\
\n\
07:30 Uhr: Haferflocken mit Banane\n\
13:00 Uhr: Sandwich\n\
16:00 Uhr: Nüsse\n\
18:30 Uhr: Pasta mit Tomatensauce\n\
Tag 3 (Wochenende)\n\
\n\
09:00 Uhr: Rührei mit Toast\n\
14:00 Uhr: Burger\n\
17:00 Uhr: Joghurt\n\
19:30 Uhr: Pizza";
            case "about":
                return "In letzter Zeit fühle ich mich in meinem Körper nicht mehr wohl und habe Schwierigkeiten, mein Wunschgewicht zu erreichen. Trotz eigener Bemühungen fehlt mir oft die Motivation, die richtigen Entscheidungen bei Ernährung und Bewegung zu treffen. Daher suche ich professionelle Unterstützung, um meine Ziele nachhaltig zu erreichen.";
            case "mygoals":
                return "Mein Hauptziel ist es, mein Gewicht zu reduzieren und einen gesunden Lebensstil zu etablieren. Ich möchte mich fitter und energiegeladener fühlen, meine Ausdauer verbessern und langfristig ein besseres Körpergefühl erreichen. Darüber hinaus ist es mir wichtig, gesunde Gewohnheiten zu entwickeln, die ich dauerhaft beibehalten kann.";
            case "youdrink":
                return "Morgens trinke ich etwa 250 ml Kaffee und ein Glas Wasser (300 ml). Über den Tag verteilt trinke ich ca. 1,5 Liter Wasser und nachmittags nochmal eine Tasse Tee (200 ml). Abends trinke ich meistens noch ein Glas Wasser (300 ml).";
            case "craving":
                return "Ja, ich bekomme oft Heisshunger am späten Nachmittag, meist gegen 16:00 Uhr. In diesen Situationen greife ich häufig zu Schokolade oder Chips";
           
            case "myallergies":
                return "Ich bin allergisch gegen Nüsse und Gluten. Bei Nusskontakt bekomme ich Hautausschlag, und bei glutenhaltigen Lebensmitteln habe ich Magenbeschwerden.";
            case "orderperweek":
                return "";
            case "eatingperweek":
                return "Ich esse etwa 3-4 Mal pro Woche auswärts. Dazu zählen ein Mittagessen in der Mensa, einmal Abendessen bei meinen Eltern, und ab und zu hole ich mir etwas vom Bäcker zum Frühstück.";
            case "attentionyourdiet":
                return "Ich versuche, darauf zu achten, nicht zu viel Zucker zu essen und zumindest ein bisschen Gemüse in meine Mahlzeiten einzubauen. Es ist mir auch wichtig, regelmässig zu essen, aber manchmal greife ich auch zu schnellen Snacks oder Fertigprodukten, wenn ich wenig Zeit habe.";
            case "trainperweek":
                return "Ich trainiere etwa 2-3 Mal pro Woche. Meistens gehe ich joggen für etwa 30 Minuten oder mache ein Workout zu Hause für ca. 45 Minuten. Ab und zu nehme ich an einem Yoga-Kurs teil, der etwa eine Stunde dauert.";
            case "traintotal":
                return "180";
            case "MeTime":
                return "Bei Stress gehe ich gerne eine Runde spazieren oder höre Musik, um abzuschalten. Manchmal greife ich auch zu einem Stück Schokolade oder rede mit einem Freund, um den Kopf frei zu bekommen.";
            case "proTag":
                return "Ich bin grösstenteils zufrieden in meinem Job. Die Arbeit ist interessant, und ich verstehe mich gut mit meinen Kollegen. Manchmal gibt es stressige Phasen, aber insgesamt fühle ich mich wohl und schätze die Sicherheit und die Entwicklungsmöglichkeiten.";

            case "Schritte":
                return "Ich habe meistens etwa 30 Minuten bis eine Stunde Me-Time pro Tag. Diese Zeit nutze ich, um ein Buch zu lesen, spazieren zu gehen oder einfach mal zu entspannen";

            case "beiStress":
                return "Ich arbeite als Marketing-Managerin in einem mittelständischen Unternehmen. Ich bin zu 80% angestellt, was sich auf vier Tage pro Woche verteilt. Meistens arbeite ich montags bis donnerstags, jeweils von 8:00 bis 17:00 Uhr.";
            case "zufrieden":
                return "Ich schlafe normalerweise etwa 6-7 Stunden pro Nacht. Unter der Woche gehe ich meistens gegen 23:00 Uhr ins Bett und stehe um 6:00 Uhr auf. Am Wochenende versuche ich, etwas länger zu schlafen, um den Schlafmangel auszugleichen.";
            case "wieverteilt":
                return "Meine Schlafqualität ist meistens okay, aber nicht perfekt. not of wache ich in der Nacht ein- oder zweimal auf und brauche dann etwas Zeit, um wieder einzuschlafen. Morgens fühle ich mich manchmal noch müde, besonders wenn ich Stress habe oder spät ins Bett gehe.";
            case "verteilt":
                return "Ja, ich fühle mich not of tagsüber müde und energielos, besonders nach dem Mittagessen. Es fällt mir dann schwer, mich auf meine Aufgaben zu konzentrieren, und ich hätte gerne mehr Energie, um produktiver zu sein.";
            case "Schlafdauer":
                return "Meine Verdauung ist meistens in Ordnung, aber hin und wieder habe ich mit Blähungen oder einem Völlegefühl zu kämpfen. Gelegentlich habe ich auch Verstopfungen, besonders wenn ich nicht genug Wasser trinke oder mich wenig bewege.";
            case "Schlafqualität":
                return "Mein Zyklus ist ziemlich regelmässig und dauert meistens 28 bis 30 Tage. Ab und zu verschiebt er sich um ein paar Tage, besonders wenn ich gestresst bin oder meine Routine sich ändert.";
            case "energielos":
                return "Für meine Regeneration nehme ich mir nach dem Training meistens Zeit für Dehnübungen, etwa 10-15 Minuten. Einmal pro Woche gönne ich mir ein heisses Bad oder gehe in die Sauna, um meine Muskeln zu entspannen. Ausserdem versuche ich, regelmässig genügend Schlaf zu bekommen, um meinem Körper die nötige Erholung zu geben.";
            case "Verdauung":
                return "Ich stehe etwa einmal pro Woche auf die Waage, meistens am Morgen, um mein Gewicht im Blick zu behalten. Manchmal lasse ich es auch länger, wenn ich mich nicht zu sehr darauf konzentrieren möchte.";
            case "Regeneration":
                return "Vor drei Jahren hatte ich eine Knieoperation aufgrund eines Meniskusschadens. Ausserdem leide ich an einer Schilddrüsenunterfunktion, die medikamentös behandelt wird. Beide sind wichtig zu berücksichtigen, wenn es um meine Fitness und Ernährung geht.";
            case "stehst":
                return "Ich nehme täglich eine Tablette zur Schilddrüsenhormonregulation (L-Thyroxin 50 mg). Ausserdem nehme ich gelegentlich Ibuprofen bei starken Kopfschmerzen oder Schmerzen nach dem Training.";
            case "Erkrankungen":
                return "Meine grössten Fehlerquellen sind unregelmässiges Essen und häufige Snacks zwischendurch, besonders wenn ich gestresst bin. Ausserdem fehlt mir manchmal die Disziplin, regelmässig zu trainieren, und ich neige dazu, auf schnelle, ungesunde Optionen zurückzugreifen, wenn ich wenig Zeit habe.";
            case "heartrate":
                return "70";
            case "uploadphotos":
                return "Upload your photos";
            case "averageperday":
                return "8000";


            default:
                return "";
        }
    };

    return (
        <div className='form'>
            <label className='form-tag typography-title-small'>
                {label}
                {helperText && <div className='helper-text'>{helperText}</div>}
                {name === "dateOfBirth" ? (
                    <input
                        type="date"
                        name={name}
                        value={value}
                        onChange={onChange}
                        placeholder={getPlaceholder()}
                    />
                ) : name === "bodyhight" || name === "currentbody" ? (
                    <input
                        // className='tissue-legs-text-area'
                        type="number"
                        name={name}
                        value={value}
                        onChange={onChange}
                        placeholder={getPlaceholder()}

                    />

                )
                    : name === "myperseverance" ? (
                        <input
                            // className='tissue-legs-text-area'
                            type="number"
                            name={name}
                            min="1"
                            max="10"
                            onChange={onChange}
                            placeholder={getPlaceholder()}
                            onInput={(e) => {
                                const value = parseInt(e.target.value, 10);
                                if (value < 1) {
                                    e.target.value = 1;
                                } else if (value > 10) {
                                    e.target.value = 10;
                                }
                            }}
                        />
                    )
                        : name === "bodyhight" ? (
                            <input
                                // className='tissue-legs-text-area'
                                type="number"
                                name={name}
                                min="1"
                                max="10"
                                onChange={onChange}
                                placeholder={getPlaceholder()}
                                onInput={(e) => {
                                    const value = parseInt(e.target.value, 10);
                                    if (value < 1) {
                                        e.target.value = 1;
                                    } else if (value > 10) {
                                        e.target.value = 10;
                                    }
                                }}
                            />
                        )
                            : name === "traintotal" ? (
                                <input
                                    // className='tissue-legs-text-area'
                                    type="number"
                                    name={name}
                                    min="1"
                                    max="20"
                                    onChange={onChange}
                                    placeholder={getPlaceholder()}

                                />
                            )
                                : name === "averageperday" ? (
                                    <input
                                        // className='tissue-legs-text-area'
                                        type="number"
                                        name={name}
                                        min="1"
                                        max="20"
                                        onChange={onChange}
                                        placeholder={getPlaceholder()}

                                    />
                                )

                                    : name === "targetWeight" ? (
                                        <div className='target-weight-container'>
                                            <div className='target-weight-fields'>
                                                <div>
                                                    <p>
                                                        Aktuelles Gewicht in kg
                                                    </p>

                                                    <input style={{ margin: '0px' }}
                                                        type="number"
                                                        name={name}
                                                        placeholder='60'
                                                    />
                                                </div>
                                                {/* <h3>KG</h3> */}
                                                <img src={weightarrow} alt="Weight Arrow" className='weight-arrow' />

                                                <div>
                                                    <p>
                                                        Zielgewicht in kg
                                                    </p>
                                                    <input style={{ margin: '0px' }}
                                                        type="number"
                                                        name={name}
                                                        placeholder='55'
                                                    />
                                                </div>
                                                {/* <h3>KG</h3> */}
                                            </div>
                                            <div className="slider-container">
                                                <div className="weight-display">
                                                    <span>Geplante Wochen zur Erreichung des Zielgewichts: {value} {value === 1 ? 'Woche' : 'Wochen'}</span>
                                                </div>
                                            </div>
                                            <Slider
                                                defaultValue={1}
                                                aria-label="Default"
                                                valueLabelDisplay="auto"
                                                min={1}
                                                max={52}
                                                value={value}
                                                onChange={onSliderChange}
                                            />
                                        </div>
                                    ) : name === "mygoals" || name === "myallergies" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                placeholder={getPlaceholder()}
                                                rows="6"
                                            />
                                        </div>
                                    ) : name === "about" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "craving" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "youdrink" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "eatingperweek" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "attentionyourdiet" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "trainperweek" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "MeTime" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "proTag" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "Schritte" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "beiStress" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "zufrieden" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "wieverteilt" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "verteilt" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "Schlafdauer" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "Schlafqualität" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "energielos" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "Regeneration" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "dietwithtimestamp" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "Verdauung" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "stehst" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    ) : name === "Erkrankungen" ? (
                                        <div>
                                            <textarea
                                                className='tissue-legs-text-area'
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                rows="6"
                                                placeholder={getPlaceholder()}
                                            />
                                        </div>
                                    )
                                        : name === "heartrate" ? (
                                            <div>
                                                <div className='finger-section'>
                                                    <img src={finger} alt="f2" className='finger-img' />
                                                    <p style={{ fontSize: '12px', margin:'0'}}>
                                                        Einfach zwei oder drei Finger auf die Innenseite des Handgelenks unterhalb des Daumens legen und 30 Sekunden lang die Schläge mitzählen. Der Wert mal zwei ergibt den Puls pro Minute.
                                                    </p>
                                                </div>
                                                <input
                                                    style={{ marginTop: '20px !important' }}
                                                    // className='tissue-legs-text-area'
                                                    type="number"
                                                    name={name}
                                                    min="1"
                                                    max="10"
                                                    onChange={onChange}
                                                    placeholder={getPlaceholder()}

                                                />

                                            </div>
                                        ) : name === "uploadphotos" ? (
                                            <div className='profile-img-main-box-responsive'>
                                                <div className='profile-img-main-box'>
                                                    <div className='photo-main'>
                                                        <p className="typography-body-large">Frontansicht </p>
                                                        <label>
                                                            <img
                                                                className='upload-img'
                                                                src={frontImage || frontimg}
                                                                alt="Front Photo"
                                                            />
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                style={{ display: 'none' }}
                                                                ref={frontFileInputRef}
                                                                onChange={(e) => handleFileChange(e, setFrontImage, `${name}Front`)}
                                                            />
                                                        </label>
                                                    </div>
                                                    <div className='photo-main'>
                                                        <p className="typography-body-large">Rückansicht</p>
                                                        <label>
                                                            <img
                                                                className='upload-img'
                                                                src={backImage || backimg}
                                                                alt="Back Photo"
                                                            />
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                style={{ display: 'none' }}
                                                                ref={backFileInputRef}
                                                                onChange={(e) => handleFileChange(e, setBackImage, `${name}Back`)}
                                                            />
                                                        </label>
                                                    </div>
                                                    <div className='photo-main'>
                                                        <p className="typography-body-large">Seitenansicht</p>
                                                        <label>
                                                            <img
                                                                className='upload-img'
                                                                src={sideImage || sideimg}
                                                                alt="Side Photo"
                                                            />
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                style={{ display: 'none' }}
                                                                ref={sideFileInputRef}
                                                                onChange={(e) => handleFileChange(e, setSideImage, `${name}Side`)}
                                                            />
                                                        </label>
                                                    </div>
                                                    <div className='photo-main'>
                                                        <p className="typography-body-large">Bauchansicht</p>
                                                        <label>
                                                            <img
                                                                className='upload-img'
                                                                src={stomachImage || stomachimg}
                                                                alt="Stomach Photo"
                                                            />
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                style={{ display: 'none' }}
                                                                ref={stomachFileInputRef}
                                                                onChange={(e) => handleFileChange(e, setStomachImage, `${name}Stomach`)}
                                                            />
                                                        </label>
                                                    </div>
                                                    <div className='photo-main'>
                                                        <p className="typography-body-large">Oberschenkelansicht </p>
                                                        <label>
                                                            <img
                                                                className='upload-img'
                                                                src={thighsImage || thighsimg}
                                                                alt="Thighs Photo"
                                                                style={{ objectFit: 'contain', display: 'inline' }}
                                                            />
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                style={{ display: 'none' }}
                                                                ref={thighsFileInputRef}
                                                                onChange={(e) => handleFileChange(e, setThighsImage, `${name}Thighs`)}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : options ? (
                                            <>
                                                <div className="custom-select-wrapper">
                                                    <div
                                                        className="custom-select"
                                                        onClick={toggleDropdown}
                                                    >
                                                        <div className="selected-option">
                                                            {selectedOption ? options.find(option => option.value === selectedOption)?.label : 'Option auswählen...'}
                                                        </div>
                                                        <div className={`options-list ${isDropdownOpen ? 'open' : ''}`}>
                                                            {options.map(option => (
                                                                <div
                                                                    key={option.value}
                                                                    className={`custom-option ${selectedOption === option.value ? 'selected' : ''}`}
                                                                    onClick={() => handleSelectChange(option.value)}
                                                                >
                                                                    {option.label}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <select className='form-select-drop' name={name} value={selectedOption} onChange={handleSelectChange} style={{ background: '#ffff' }}>
                                    {options.map(option => (
                                        <option className='form-select-option' key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select> */}

                                            </>
                                        ) : (
                                            <input
                                                // className='tissue-legs-text-area'
                                                type="text"
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                placeholder={getPlaceholder()}

                                            />
                                        )}
            </label>
        </div>
    );
};

export default Form;
