import React, { useState, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import LinearProgress from '@mui/material/LinearProgress'; // Import the LinearProgress component
import 'react-calendar/dist/Calendar.css';
import logo from '../src/assets/logo.webp';
import f1 from '../src/assets/f1.png';
import f2 from '../src/assets/f2.png';
import f3 from '../src/assets/f3.png';
import f4 from '../src/assets/f4.png';
import f5 from '../src/assets/f5.png';
import f6 from '../src/assets/f6.png';
import f7 from '../src/assets/f7.png';
import f8 from '../src/assets/f8.png';
import f9 from '../src/assets/f9.png';
import rotate from '../src/assets/rotate_plate.png';
import lady from '../src/assets/lady1.png';
import Slider from '@mui/material/Slider';  // Import the Slider component
import './App.css';
import 'animate.css';
import Form from "./Components/form";

function App() {
  const formSteps = [

    'Name',
    'Geburtsdatum',
    'E-Mail',
    'Körpergrösse in cm',
    'Aktuelles Körpergewicht in kg',
    'Welches Zielgewicht in kg möchtest du erreichen, und in wie vielen Wochen planst du, dieses Ziel zu erreichen?',
    'Erzähle uns ein wenig von dir. Wieso kommt es dazu, dass du unsere Hilfe in Anspruch nimmst?',
    'Meine Ziele',
    'Was ist für dich deine körperliche Problemzone?',
    'Als Kind/Jugendliche*r sah mein Körper so aus',
    'Hast/Hattest du eine Essstörung?',
    'Mein Durchhaltevermögen von 1-10',
    'Dein Gewebe an den Beinen ist',
    'Notiere hier über 3 Tage deine Ernährung mit Zeitangaben (integriere bitte auch einen Tag vom Wochenende)',
    'Wenn ich meine Ernährung betiteln müsste, wäre es am ehesten',
    'Was und wie viel trinkst du über den Tag verteilt?',
    'Hast du Heisshunger? Wenn ja, um welche Zeit und was isst du in diesen Situationen?',
    'Gläser Alkohol pro Woche',
    'Meine Allergien',
    // 'Take-away oder auswärts bestellen pro Woche',
    'Auswärts essen pro Woche (hier zählt auch Bäckerei, Mensa, bei Eltern essen etc. dazu, alles, was du nicht selbst zubereitet hast)',
    'Auf was achtest du bei deiner Ernährung? Was ist dir wichtig?',
    'Wie oft trainierst du pro Woche? Welche Sportart und wie lange?',
    'Wie viele Minuten trainierst du total pro Woche?',
    'Weisst du mit Sicherheit, wie viele Schritte du im Durchschnitt täglich machst?',
    'Wie viele Schritte machst du durchschnittlich am Tag?',
    'Wie viel Me-Time hast du pro Tag?',
    'Dein Ventil bei Stress',
    'Bist du zufrieden im Job?',
    'Als was arbeitest du, wie viel Prozent und wie verteilt?',
    'Wie viele Stunden schläfst du im Durchschnitt pro Nacht?',
    'Wie gut schläfst du allgemein?',
    'Fühlst du dich am Tag of müde und energielos?',
    'Wie ist deine Verdauung?',
    'Wie regelmässig ist dein Zyklus?',
    'Was machst du für deine Regeneration, wie oft und wie lange?',
    'Wie oft stehst du auf die Waage?',
    'Gibt es Erkrankungen oder Operationen welche für uns von Relevanz sind?',
    'Gibt es Medikamente welche du regelmässig konsumierst?',
    'Was denkst du sind deine Fehlerquellen, dass du dein Ziel noch nicht erreicht hast?',
    'Wie hoch ist dein durchschnittlicher Ruhepuls am Morgen vor dem ersten Aufstehen?',
    'Unter Stress esse ich mehr Süsses/Salziges/Brot?',
    'Je intensiver der Sport, desto lebendiger fühle ich mich?',
    'In stressigen Situationen bleibe ich ruhig?',
    'Ich gehe erst ins Bett, wenn alles erledigt ist?',
    'Ich vergesse über den Tag oft, richtig zu essen, und hole dies am Abend in aller Ruhe nach?',
    'Lade bitte je ein Foto von vorne, hinten, der Seite, deinem Bauch und deinen Oberschenkeln hoch. Achte darauf, dass die Körperpartien gut erkennbar sind. Die Fotos helfen uns, deinen Fortschritt nachzuvollziehen.',
    // 'Mögliche Körpertypen für die Schlussauswertung',
   

  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState({
    name: '',
    dateOfBirth: '',
    Email: '',
    bodyhight: '',
    currentbody: '',
    targetWeight: 1,  // Initialize with default value for the slider
    about: '',
    mygoals: '',
    physicalproblem: '',
    bodylooked: '',
    eatingdisorder: '',
    eatingdisorderDetails: '', // Initialize for the additional input
    myperseverance: '',
    tissuelegs: '',
    dietwithtimestamp: '',
    labelmydiet: '',
    youdrink: '',
    craving: '',
    glassesalcohol: '',
    myallergies: '',
    // orderperweek: '',
    eatingperweek: '',
    attentionyourdiet: '',
    trainperweek: '',
    traintotal: '',

    Schritte: '',
    MeTime: '',
    proTag: '',

    beiStress: '',
    zufrieden: '',
    wieverteilt: '',
    verteilt: '',
    Schlafdauer: '',
    Schlafqualität: '',
    energielos: '',
    Verdauung: '',
    Zyklus: '',
    Regeneration: '',
    stehst: '',
    Erkrankungen: '',

    stepseveryday: '',
    averageperday: '',
    heartrate: '',
    eatmore: '',
    feelalive: '',
    stressful: '',
    everythingisdone: '',
    eveninginpeace: '',
    uploadphotos: '',
    finalevalution: '',


  });

  const [showWarning, setShowWarning] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [vissibleAll, setVissibleAll] = useState(true);
 
  // const [seinhowStep, setShowStep] = useState(0);
  var seinhowStep = 0;
  function isStepValid() {
    const fieldName = getFieldName(currentStep);

    // Check if the field is not empty
    if (fieldName.startsWith('uploadphotos')) {
      return (
        formValues.uploadphotosFront !== undefined && formValues.uploadphotosFront !== '' &&
        formValues.uploadphotosBack !== undefined && formValues.uploadphotosBack !== '' &&
        formValues.uploadphotosSide !== undefined && formValues.uploadphotosSide !== '' &&
        formValues.uploadphotosStomach !== undefined && formValues.uploadphotosStomach !== '' &&
        formValues.uploadphotosThighs !== undefined && formValues.uploadphotosThighs !== ''
      );
    }

    const fieldValue = formValues[fieldName];
    return fieldValue !== undefined && fieldValue !== '';
  }


  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (currentStep < formSteps.length - 1) {
        console.log("next");
        
        handleNext();
      } else {
        console.log("submit");
        
        handleSubmit(); 
      }
    }
  };

  const handleSubmit = () => {
    // Your submit logic here
    console.log('Form submitted', formValues);
  };

  const handleNext = () => {
    if (isStepValid()) {
      setShowWarning(false);
      if (currentStep < formSteps.length - 1) {
        setCurrentStep(currentStep + 1);
      }
    } else {
      setShowWarning(true);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setShowWarning(false);
  };

  const handleSliderChange = (e, newValue) => {
    setFormValues({ ...formValues, targetWeight: newValue });
    setShowWarning(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getFieldName = (step) => {
    // setShowStep(step)
    switch (step) {
      case 0:
        seinhowStep = step;
        return "name";
      case 1:
        seinhowStep = step;
        return "dateOfBirth";
      case 2:
        seinhowStep = step;
        return "Email";
      case 3:
        seinhowStep = step;
        return "bodyhight";
      case 4:
        seinhowStep = step;
        return "currentbody";
      case 5:
        seinhowStep = step;
        return "targetWeight"; // Updated to match formValues key
      case 6:
        seinhowStep = step;
        return "about";
      case 7:
        seinhowStep = step;
        return "mygoals";
      case 8:
        seinhowStep = step;
        return "physicalproblem";
      case 9:
        seinhowStep = step;
        return "bodylooked";
      case 10:
        seinhowStep = step;
        return "eatingdisorder";
      case 11:
        seinhowStep = step;
        return "myperseverance";
      case 12:
        seinhowStep = step;
        return "tissuelegs";
      case 13:
        seinhowStep = step;
        return "dietwithtimestamp";
      case 14:
        seinhowStep = step;
        return "labelmydiet";
      case 15:
        seinhowStep = step;
        return "youdrink";
      case 16:
        seinhowStep = step;
        return "craving";
      case 17:
        seinhowStep = step;
        return "glassesalcohol";
      case 18:
        seinhowStep = step;
        return "myallergies";
        // case 19:
        seinhowStep = step;
      //   return "orderperweek";
      case 19:
        seinhowStep = step;
        return "eatingperweek";
      case 20:
        seinhowStep = step;
        return "attentionyourdiet";
      case 21:
        seinhowStep = step;
        return "trainperweek";
      case 22:
        seinhowStep = step;
        return "traintotal";
      case 23:
        seinhowStep = step;
        return "stepseveryday";
      case 24:
        seinhowStep = step;
        return "averageperday";
      case 25:
        seinhowStep = step;
        return "Schritte";
      case 26:
        seinhowStep = step;
        return "MeTime";
      case 27:
        seinhowStep = step;
        return "proTag";
      case 28:
        seinhowStep = step;
        return "beiStress";
      case 29:
        seinhowStep = step;
        return "zufrieden";
      case 30:
        seinhowStep = step;
        return "wieverteilt";
      case 31:
        seinhowStep = step;
        return "verteilt";
      case 32:
        seinhowStep = step;
        return "Schlafdauer";
      case 33:
        seinhowStep = step;
        return "Schlafqualität";
      case 34:
        seinhowStep = step;
        return "energielos";
      case 35:
        seinhowStep = step;
        return "Verdauung";
      case 36:
        seinhowStep = step;
        return "Regeneration";
      case 37:
        seinhowStep = step;
        return "stehst";
      case 38:
        seinhowStep = step;
        return "Erkrankungen";
      case 39:
        seinhowStep = step;
        return "heartrate";
      case 40:
        seinhowStep = step;
        return "eatmore";
      case 41:
        seinhowStep = step;
        return "feelalive";
      case 42:
        seinhowStep = step;
        return "stressful";
      case 43:
        seinhowStep = step;
        return "everythingisdone";
      case 44:
        seinhowStep = step;
        return "eveninginpeace";
      case 45:
        seinhowStep = step;
        return "uploadphotos";
      // case 46:
      //   seinhowStep = step;
      //   return "finalevalution";
      case 47:
        seinhowStep = step;
        return "Zyklus";
      
      default:
        seinhowStep = 0;
        return "";
    }
  };

  const getOptions = (step) => {
    switch (step) {
      case 8:
        return [
          // { value: '1', label: 'Select an option' },
          { value: 'Bauch', label: 'Bauch' },
          { value: 'Oberschenkel / Po', label: 'Oberschenkel / Po' },
          { value: 'Arme', label: 'Arme' },
          { value: 'Oberschenkel / Po und Bauch', label: 'Oberschenkel / Po und Bauch' },
          { value: 'Oberschenkel und Arme', label: 'Oberschenkel und Arme' },
          { value: 'Ganzer Körper', label: 'Ganzer Körper' },
        ];
      case 9:
        return [
          // { value: '2', label: 'Select an option' },
          { value: 'Dünn / drahtig', label: 'Dünn / drahtig' },
          { value: 'Athletisch / sichtbare Muskulatur', label: 'Athletisch / sichtbare Muskulatur' },
          { value: 'Massiger als der Durchschnitt', label: 'Massiger als der Durchschnitt' },
        ];
      case 10:
        return [
          // { value: '3', label: 'Select an option' },
          { value: 'Nein', label: 'Nein' },
          { value: 'Ja, Magersucht ', label: 'Ja, Magersucht ' },
          { value: 'Ja, Bulimie', label: 'Ja, Bulimie' },
          { value: 'Ja, Binge eating', label: 'Ja, Binge eating' },
        ];
      // case 11:
      //   return [
      //     { value: '', label: 'Select an option' },
      //     { value: '<6', label: '<6' },
      //     { value: '>=6', label: '>=6' },
      //   ];
      case 12:
        return [
          // { value: '4', label: 'Select an option' },
          { value: 'schwammig als wäre Wasser drin', label: 'Schwammig als wäre Wasser drin' },
          { value: 'Cellulite', label: 'Cellulite' },
          { value: 'beim kneiffen tut mir mein Gewebe weh', label: 'Beim kneiffen tut mir mein Gewebe weh' },
          { value: 'unauffällig', label: 'Unauffällig' },
        ];

      case 14:
        return [
          // { value: '5', label: 'Select an option' },
          { value: 'No specific dietary form', label: 'Keine spezifische Ernährungsform' },
          { value: 'Vegan ', label: 'Vegan ' },
          { value: 'vegetarisch ', label: 'Vegetarisch' },
          { value: 'Low carb ', label: 'Low carb ' },
          { value: 'intervallfasten', label: 'Intervallfasten' },
          { value: 'High Protein ', label: 'High Protein ' },
          { value: 'ausgewogen ', label: 'Ausgewogen ' },
        ];
      case 17:
        return [
          // { value: '6', label: 'Select an option' },
          { value: 'Ich trinke kein Alkohol', label: 'Ich trinke kein Alkohol' },
          { value: '1-2 Gläser', label: '1-2 Gläser' },
          { value: '3-4 Gläser', label: '3-4 Gläser' },
          { value: '5 und mehr Gläser', label: '5 und mehr Gläser' },
        ];
      // case 22:
      //   return [
      //     // { value: '7', label: 'Select an option' },
      //     { value: 'Körpertypen <300 min pro Woche ', label: 'Körpertypen <300 min pro Woche ' },
      //     { value: 'Kopflastige Körpertypen >300 min pro Woche ', label: 'Kopflastige Körpertypen >300 min pro Woche ' },
      //   ];
      case 23:
        return [
          // { value: '8', label: 'Select an option' },
          { value: 'ja ', label: 'Ja ' },
          { value: 'Nein', label: 'Nein' },
        ];
      // case 24:
      //   return [
      //     // { value: '9', label: 'Select an option' },
      //     { value: 'Anzahl Schritte ', label: 'Anzahl Schritte ' },
      //     { value: 'Pal Wert', label: 'Pal Wert' },
      //   ];
      // case 39:
      //   return [
      //     // { value: '10', label: 'Select an option' },
      //     { value: '>80', label: '>80' },
      //     { value: '<60', label: '<60' },

      //   ];
      case 40:
        return [
          // { value: '11', label: 'Select an option' },
          { value: 'ja', label: 'Ja' },
          { value: 'Nein ich esse weniger', label: 'Nein ich esse weniger' },
          { value: 'Nein ich esse wie sonst auch', label: 'Nein ich esse wie sonst auch' },
        ];
      case 41:
        return [
          // { value: '12', label: 'Select an option' },
          { value: 'ja1', label: 'Ja' },
          { value: 'Nein1', label: 'Nein' },
        ];
      case 42:
        return [
          // { value: '13', label: 'Select an option' },
          { value: 'ja2', label: 'Ja' },
          { value: 'Nein2', label: 'Nein' },
        ];
      case 43:
        return [
          // { value: '14', label: 'Select an option' },
          { value: 'ja3', label: 'Ja' },
          { value: 'Nein3', label: 'Nein' },
        ];
      case 44:
        return [
          // { value: '15', label: 'Select an option' },
          { value: 'ja4', label: 'Ja' },
          { value: 'Nein4', label: 'Nein' },
        ];
      // case 46:
      //   return [
      //     // { value: '16', label: 'Select an option' },
      //     { value: 'Karotte: Seide', label: 'Karotte: Seide' },
      //     { value: 'Apfel: Wolle ', label: 'Apfel: Wolle ' },
      //     { value: 'Avocado: Kork ', label: 'Avocado: Kork ' },
      //     { value: 'Zucchetti: Holz ', label: 'Zucchetti: Holz ' },
      //     { value: 'Ananas: Leder ', label: 'Ananas: Leder ' },
      //     { value: 'Birne: Lehm  ', label: 'Birne: Lehm  ' },
      //     { value: 'Tomate: Stein  ', label: 'Tomate: Stein  ' },
      //     { value: 'Mais: Metall ', label: 'Mais: Metall ' },
      //   ];
      default:
        return null;
    }
  };

  const progressPercentage = (currentStep / (formSteps.length - 1)) * 100;

  return (
    <div>

      <div className='form-under-box' onKeyDown={handleKeyDown} tabIndex="0">

        <section>
          <div className="lady-main">
            <div className='lady-img' >
              <div className='ladyy' style={{ width: '100%' }}>
                <img src={lady} alt="lady" className='fruit-img' />
              </div>
              {/* <div className='animate__animated animate__fadeInLeft' style={{ position:'absolute',left:'20%',top: '65%' }}>
                <p style={{color:'#fff',backgroundColor:'#ff4000', padding:'2px 5px 2px 20px',borderRadius:'70px 10px 0px 0px',fontSize:'14px' }} >
                  Create healthy habits, not restrictions
                </p>
                <p className='animate__animated animate__fadeInLeft' style={{color:'#fff',backgroundColor:'#ff4000', padding:'2px 5px 2px 20px',borderRadius:'70px 10px 0px 0px',fontSize:'14px' }}>
                  Take care of your body. It’s the only place you have to live
                </p>
                <p className='animate__animated animate__fadeInLeft' style={{color:'#fff',backgroundColor:'#ff4000', padding:'2px 5px 2px 20px',borderRadius:'70px 10px 0px 0px',fontSize:'14px' }}>
                  Healthy is an outfit that looks different on everybody.
                </p>
              </div> */}
            </div>

            {/* <div> <img src={f2} alt="f2" className='fruit-img' /></div> */}
            {/* <div> <img src={f3} alt="f3" className='fruit-img' /></div> */}
            {/* <div> <img src={f3} alt="f5" className='fruit-img' /></div> */}
            {/* <div> <img src={f1} alt="f1" className='fruit-img' /></div> */}
          </div>
          <div className="leaf leaf1">
            {/* <div> <img src={f4} alt="f4" className='fruit-img' /></div> */}
            {/* <div> <img src={f5} alt="f5" className='fruit-img' /></div> */}
            {/* <div> <img src={f6} alt="f" className='fruit-img' /></div> */}
            {/* <div> <img src={f6} alt="f6" className='fruit-img' /></div> */}
            <div> <img src={rotate} alt="rotate" className='fruit-img-rotate' /></div>
          </div>
          <div className="leaf leaf2">
          </div>
        </section>

        <div className='form-main-div  '>
          {vissibleAll ?
          <>
          <Form

            label={formSteps[currentStep]}
            comments={formSteps[currentStep]}
            name={getFieldName(currentStep)}
            value={formValues[getFieldName(currentStep)]}
            onChange={handleInputChange}
            onSliderChange={handleSliderChange}
            options={getOptions(currentStep)}
            helperText={currentStep === 45 ? "Füge die Bilder hinzu, indem du auf die jeweilige Abbildung klickst" : ""
              // currentStep === 3 ? "zb 160cm" :
                // currentStep === 4 ? "zb 65kg" :
                  // currentStep === 5 ? "Reiter muss unrealistisches Gewichtsziel angeben" :
                  // currentStep === 13 ? "zb. 9:00 Müesli aus 250g Magerquark,1 Handvoll Beeren und 50g Haferflocken" :
                    // currentStep === 19 ? "'zb2x'" :
                      // currentStep === 20 ? "'zb2x'" :

                        // currentStep === 26 ? "'zb 10 Min'" :
                          // currentStep === 35 ? " 'zb Massage, Blackroll, schröpfen etc'" :
            }

          />
          {showWarning && (
            <div className="warning-message">
              {/* Wert erforderlich */}
              {seinhowStep == 45 ? <>
                <p>
                  Frontansicht (Foto von vorne) <br/>

                  Rückansicht (Foto von hinten)<br/>

                  Seitenansicht (Foto von der Seite)<br/>

                  Bauchansicht (Foto vom Bauch)<br/>

                  Oberschenkelansicht (Foto von den Oberschenkeln)
                </p>
              </> : "Wert erforderlich"}
            </div>
          )}
          <div className='form-button-main'>
            {currentStep > 0 && (
              <button className='back-button' onClick={handleBack}>
                <ArrowBackIcon /> Zurück
              </button>
            )}
            {currentStep < formSteps.length - 1 ? (
              <button
                className='next-button'
                type="button"
                onClick={handleNext}
              >
                Nächste <ArrowForwardIcon />
              </button>
            ) : (
              <button className='submit-button' onClick={()=>{
                // setVissibleAll(false)
                if (isStepValid()) {
                  setShowWarning(false);
                  setVissibleAll(false)
                } else {
                  setShowWarning(true);
                }
              }} type="submit">
                Einreichen <DoneOutlineIcon />
              </button>
            )}
          </div>
          <LinearProgress variant="determinate" value={progressPercentage} className="progress-bar" />
          <div className="page-number"> Seite <span>{currentStep + 1}</span> von <span>{formSteps.length}</span></div>  {/* Page numbering */}
          </>:<>
           <h2 style={{fontSize:'20px',textAlign:'center',fontWeight:'bold',marginTop:'50px',marginBottom:'50px',fontFamily: 'CustomFont',lineHeight:'1.5,' }} >
           Danke, dass du dir die Zeit für unsere Körpertyp-Analyse genommen hast! Wir werten deine Antworten jetzt aus und melden uns in der Regel innerhalb von 2-3 Arbeitstagen mit den Ergebnissen bei dir. Bis bald!
           </h2>
          </>
          }
        </div>


        {(currentStep !== 46 || !isMobile) && (
          <div className='nav-logo'>
            <h1 className="typography-display1  animate__animated animate__fadeInLeft ">Körpertyp Analyse</h1>
            <img className='animate__animated animate__fadeInRight' src={logo} alt="Weight Arrow" />
          </div>
        )}
        {/* <section>
          <div> <img src={rotate} alt="rotate" className='fruit-img' /></div>
        </section> */}
      </div>
    </div>
  );
}

export default App;
